document.addEventListener('DOMContentLoaded', function(){
    window.lazyload = new LazyLoad({
        cancel_on_exit: false,
        callback_error: function(img){
            img.setAttribute("src", "/assets/media/no-image.jpg");
        }
    });
});

$(function(){

    $('.section-faq .faq-item-q').on('click', function(){
        $('.faq-item').removeClass('active');

        $(this).parent().addClass('active');
        $(document).on('click', function(event){
            if($(event.target).closest('.faq-item').length) return;
            $('.faq-item').removeClass('active');
            $(document).unbind('click');
        });
    });

    if($('.header-slide').length > 1) {
        let autoplaySpeed = $('.header-slides').data('slick-speed') ?? 3000;
        $('.header-slides').slick({
            arrows: false,
            speed: 500,
            dots: true,
            swipe: false,
            dotsClass: 'header-slider-dots controls d-flex flex-wrap list-unstyled container z-index-3 justify-content-center justify-content-md-start',
            autoplay: Number(autoplaySpeed) > 0 ? true : false,
            autoplaySpeed: Number(autoplaySpeed)
        });
    }

    $('.reviews-slick').slick({
        slidesToShow: 3,
        arrows: true,
        speed: 500,
        //dots: true,
        swipe: false,
        dotsClass: 'controls mt-5 d-flex pl-5 list-unstyled controls-dark',
        prevArrow: '<span class="slick-arrow slick-arrow-left"><i class="fas fa-chevron-left"></i></span>',
        nextArrow: '<span class="slick-arrow slick-arrow-right"><i class="fas fa-chevron-right"></i></span>',
        responsive: [
            {
				breakpoint: 960,
				settings: {
                    slidesToShow: 2,
                    swipe: true,
                    dots: false
				}
			},
			{
				breakpoint: 767,
				settings: {
                    slidesToShow: 1,
                    swipe: true,
                    dots: false
				}
			}
		]
    });

    $(document).on('af_complete', function(event, response) {
        var form = response.form,
            targetBtn = form.find('.btn');

        if(response['success']){
            location.href = document.baseURI+'spasibo-za-vashu-zayavku/';
        }
        if(response['error']){
            targetBtn.removeAttr('disabled').prop('disabled', false);
        }
        
    });

    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });

    // Scroll Top Button - Show
    var $scrollTop = $('.scroll-top');

    $(window).on('load scroll', function() {
        if ( $(window).scrollTop() >= 400 ) {
            $scrollTop.addClass('show');
        } else {
            $scrollTop.removeClass('show');
        }
    });

    // On click animate to top
    $scrollTop.on('click', function (e) {
        $('html, body').animate({
            'scrollTop': 0
        }, 800);
        e.preventDefault();
    });

    let toggleMenuButton = $('.open-mobile-slide-menu'),
        toggleMenuTitle = toggleMenuButton.data('title');

	$('#topnav > ul').clone().prependTo('.mobile-slide-menu');
	$('.mobile-slide-menu').hcOffcanvasNav({
        disableAt: 768,
        customToggle: toggleMenuButton,
        navTitle: toggleMenuTitle,
        levelTitles: true,
        levelTitleAsBack: true,
        closeOnClick: false
    });

    $('.visible-privacy-policy').on('change', function(){
        $(this).closest('form').find('.hidden-privacy-policy').removeClass('error');
    });

    let YouTubeContainers = document.querySelectorAll(".embed-youtube");

    // Iterate over every YouTube container you may have
    for (let i = 0; i < YouTubeContainers.length; i++) {
        let container = YouTubeContainers[i];
        let imageSource = "https://img.youtube.com/vi/"+ container.dataset.videoId +"/sddefault.jpg"; 

        // Load the Thumbnail Image asynchronously
        let image = new Image();
        image.src = imageSource;
        image.classList = "d-inline";
        image.addEventListener("load", function() {
            container.appendChild(image);
        });

        // When the user clicks on the container, load the embedded YouTube video
        container.addEventListener("click", function() {
            let iframe = document.createElement( "iframe" );

            iframe.setAttribute("frameborder", "0");
            iframe.setAttribute("allowfullscreen", "");
            iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture");
            // Important: add the autoplay GET parameter, otherwise the user would need to click over the YouTube video again to play it 
            iframe.setAttribute("src", "https://www.youtube.com/embed/"+ this.dataset.videoId +"?rel=0&showinfo=0&autoplay=1");

            // Clear Thumbnail and load the YouTube iframe
            this.innerHTML = "";
            this.appendChild( iframe );
        });
    }
    
});